.appear {
  transition: width 600ms ease-out, height 600ms ease-out;
}

.accent {
  color: rgb(192, 175, 127);
}
.bg-accent {
  background-color: rgb(192, 175, 127);
}

.txt-shadow {
  text-shadow: 1px 1px 0px #00000029;
}

.card {
  background: #756656;
}

.fat {
  font-weight: 600;
}

.glass {
  background: rgba(255, 255, 255, 0.548);

  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
}

.watermark {
  font: bold 40px arial, sans-serif;
  background-color: #565656;
  color: transparent;
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.bg00 {
  color: #fafafa;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgb(194, 57, 206) 0%,
    rgb(114, 99, 228) 100%
  );
  color: #fafafa;
}
.bg01 {
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgb(0, 118, 208) 0%,
    rgb(6, 175, 209) 90.1%
  );
  color: #fafafa;
}
.bg02 {
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgb(230, 164, 192) 0%,
    rgb(148, 187, 233) 100%
  );
  color: #000000;
}
.bg03 {
  background-color: #fbda61;
  background-image: linear-gradient(45deg, #fbda61 0%, #ff5acd 100%);
  color: #000000;
}
.bg04 {
  background: linear-gradient(45deg, #f1d397, #b6bd9e, #c3e6d2);
  color: #000000;
}
.bg08 {
  background-color: #faaca8;
  background-image: linear-gradient(37deg, #faaca8 0%, #ddd6f3 100%);
  color: #000000;
}
