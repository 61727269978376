@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
#root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
}
body {
  width: 100%;
  height: 100vh;
  font-family: "Poppins", sans-serif;

  background-position: 0 0;
  background-attachment: fixed;
  background-size: cover;
}
